// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-alright-lads-js": () => import("./../../../src/pages/portfolio/alright-lads.js" /* webpackChunkName: "component---src-pages-portfolio-alright-lads-js" */),
  "component---src-pages-portfolio-breaking-news-js": () => import("./../../../src/pages/portfolio/breaking-news.js" /* webpackChunkName: "component---src-pages-portfolio-breaking-news-js" */),
  "component---src-pages-portfolio-gig-js": () => import("./../../../src/pages/portfolio/gig.js" /* webpackChunkName: "component---src-pages-portfolio-gig-js" */),
  "component---src-pages-portfolio-i-am-contagious-js": () => import("./../../../src/pages/portfolio/i-am-contagious.js" /* webpackChunkName: "component---src-pages-portfolio-i-am-contagious-js" */),
  "component---src-pages-portfolio-i-heard-it-said-js": () => import("./../../../src/pages/portfolio/i-heard-it-said.js" /* webpackChunkName: "component---src-pages-portfolio-i-heard-it-said-js" */),
  "component---src-pages-portfolio-iggy-pop-howling-at-the-moon-js": () => import("./../../../src/pages/portfolio/iggy-pop-howling-at-the-moon.js" /* webpackChunkName: "component---src-pages-portfolio-iggy-pop-howling-at-the-moon-js" */),
  "component---src-pages-portfolio-ill-song-js": () => import("./../../../src/pages/portfolio/ill-song.js" /* webpackChunkName: "component---src-pages-portfolio-ill-song-js" */),
  "component---src-pages-portfolio-invitation-to-my-demon-sister-js": () => import("./../../../src/pages/portfolio/invitation-to-my-demon-sister.js" /* webpackChunkName: "component---src-pages-portfolio-invitation-to-my-demon-sister-js" */),
  "component---src-pages-portfolio-italians-want-the-monarchy-back-js": () => import("./../../../src/pages/portfolio/italians-want-the-monarchy-back.js" /* webpackChunkName: "component---src-pages-portfolio-italians-want-the-monarchy-back-js" */),
  "component---src-pages-portfolio-kick-him-out-the-disco-js": () => import("./../../../src/pages/portfolio/kick-him-out-the-disco.js" /* webpackChunkName: "component---src-pages-portfolio-kick-him-out-the-disco-js" */),
  "component---src-pages-portfolio-my-hero-js": () => import("./../../../src/pages/portfolio/my-hero.js" /* webpackChunkName: "component---src-pages-portfolio-my-hero-js" */),
  "component---src-pages-portfolio-one-one-three-js": () => import("./../../../src/pages/portfolio/one-one-three.js" /* webpackChunkName: "component---src-pages-portfolio-one-one-three-js" */),
  "component---src-pages-portfolio-rebel-dykes-js": () => import("./../../../src/pages/portfolio/rebel-dykes.js" /* webpackChunkName: "component---src-pages-portfolio-rebel-dykes-js" */),
  "component---src-pages-portfolio-space-dick-js": () => import("./../../../src/pages/portfolio/space-dick.js" /* webpackChunkName: "component---src-pages-portfolio-space-dick-js" */),
  "component---src-pages-portfolio-walking-woman-js": () => import("./../../../src/pages/portfolio/walking-woman.js" /* webpackChunkName: "component---src-pages-portfolio-walking-woman-js" */)
}

